import { css } from '@emotion/react';

const typography = {
  head1: css`
    /* Head 1 */
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 90px; /* 150% */
  `,

  head2: css`
    /* Head 2 */
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px; /* 150% */
  `,

  head3: css`
    /* Head 3 */
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 150% */
  `,

  subTitle1: css`
    /* Subtitle 1 */
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 45px; /* 140.625% */
  `,
  subTitle2: css`
    /* Subtitle 2 */
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 150% */
  `,
  body1: css`
    /* Body 1 */
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
  `,
  body2: css`
    /* Body 2 */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  `,
  body3: css`
    /* body 3 */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */
  `,
  body4: css`
    /* Body 4 */
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 150% */
  `,
  body5: css`
    /* Body 5 */
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 45px; /* 150% */
  `,
  body6: css`
    /* Body 6 */
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
  `,
  button: css`
    /* Button */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */
  `,
  caption: css`
    /* Caption */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  `,
  badge: css`
    /* Badge */
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
  `,
  headMo1: css`
    /* Head_MO 1 */
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 150% */
  `,
  headMo2: css`
    /* Head_MO 2 */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 150% */
  `,
  bodyMo1: css`
    /* Body_MO 1 */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
  `,
  bodyMo2: css`
    /* Body_MO 2 */
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
  `,
  bodyMo3: css`
    /* Body_MO 3 */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  `,
};

export default typography;
