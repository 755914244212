import styled from "@emotion/styled";

import logoImage from "assets/images/logo.png";
// import logoBlackImage from "assets/images/logo_black.png";
import { css } from "@emotion/react";

export const SHeader = styled.header<IsActive>`
  position: sticky;
  top: 0;
  z-index: 100;

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 50px;
  padding: 0 20px;

  transition: background 1s, border-bottom 1s;
  ${({ isActive }) =>
    isActive &&
    css`
      background: #fff;
      border-bottom: 1px solid var(--Secondary-2, #c0c3cd) !important;
    `}

  ${({ theme }) => theme.media.lg} {
    height: 90px;
    padding: 0 120px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
`;

export const HeaderLogo = styled.div<IsActive>`
  width: 120px;
  height: 22.6px;

  background: url(${logoImage}) center;
  background-size: contain;
  background-repeat: no-repeat;

  transition: background 1s;
  ${({ isActive }) =>
    isActive &&
    css`
      background: url(${logoImage}) center;

      background-size: contain;
      background-repeat: no-repeat;
    `}

  ${({ theme }) => theme.media.lg} {
    width: 200px;
    height: 37.717px;
  }
`;

export const MenuIconWrapper = styled.div<IsActive>`
  svg {
    path {
      transition: fill 1s;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      svg {
        path {
          fill: #333;
        }
      }
    `}

  ${({ theme }) => theme.media.lg} {
    display: none;
  }
`;

export const NavigationList = styled.ul<IsActive>`
  display: none;

  ${({ theme }) => theme.media.lg} {
    display: flex;
    padding: 30px 60px;
    gap: 120px;

    li,
    button {
      color: #fff;
      cursor: pointer;
      transition: color 1s;

      ${({ theme }) => theme.typography.body3}
    }

    ${({ isActive }) =>
      isActive &&
      css`
        li,
        button {
          color: #333;
        }
      `}
  }
`;

export const SubMenuContainer = styled.div<IsVisible>`
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  border-top: 1px solid var(--Secondary-2, #c0c3cd);

  width: 100%;
  background: var(--Background, #fff);
  padding: 0 120px;

  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 1s;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      max-height: 218px;
    `}

  ${({ theme }) => theme.media.lg} {
    display: block;
  }
`;

export const SubMenuBlock = styled.div`
  display: flex;
  padding: 0 28px;
  justify-content: space-between;
  width: 568px;
  margin-left: auto;

  ul {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    li {
      color: var(--On-Background-1, #333);
      text-align: center;
      ${({ theme }) => theme.typography.body3}
    }
  }
`;
