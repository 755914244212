import "./styles/reset.css";
import "./styles/fonts.css";
import "./styles/globals.css";
import "./styles/colors.css";
import "./styles/modal.css";

import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import { ThemeProvider } from "@emotion/react";
import typography from "styles/typography";
import Modal from "react-modal";
import media from "styles/media";

Modal.setAppElement("#root");

function App() {
  return (
    <>
      <ThemeProvider theme={{ typography, media }}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
