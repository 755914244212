import { withTranslation, WithTranslation } from 'react-i18next'

const LanguageSelector = ({ i18n }: WithTranslation) => {
  const onChangeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = event.target.value
    i18n.changeLanguage(newLanguage)
  }

  return (
    <select onChange={onChangeLanguage}>
      <option value='ko'>한국어</option>
      <option value='en'>English</option>
    </select>
  )
}

export default withTranslation()(LanguageSelector)