import {
  MobileSubMenuList,
  MobileSubMenuListItem,
  SMobileMenuListItem,
} from "./MobileMenuListItem.styles";

import { ReactComponent as ChevronDown } from "assets/images/header/chevron_down.svg";
import { subMenuList } from "../../Header.data";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface MobileMenuListItemProps {
  menuItem: string;
  isActive: boolean;
  subMenu: (typeof subMenuList)[number];
  // subMenu: string;
  onClick: () => void;
  onClose: () => void;
}

const MobileMenuListItem = ({
  menuItem,
  onClick,
  isActive,
  subMenu,
  onClose,
}: MobileMenuListItemProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SMobileMenuListItem onClick={onClick} isActive={isActive}>
        <span>{menuItem}</span>
        <ChevronDown />
      </SMobileMenuListItem>
      <MobileSubMenuList isActive={isActive}>
        {subMenu.map((subMenuItem) => (
          <Link
            to={subMenuItem.path}
            onClick={onClose}
            state={subMenuItem.state}
            key={t(subMenuItem.mobileName)}
          >
            <MobileSubMenuListItem>
              {t(subMenuItem.mobileName)}
            </MobileSubMenuListItem>
          </Link>
        ))}
      </MobileSubMenuList>
    </>
  );
};

export default MobileMenuListItem;
