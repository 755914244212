import {
  FooterBlock,
  FooterLogoWrapper,
  FooterMetaBlock,
  FooterTopBlock,
  FooterLanguageBlock,
  SFooter,
  FooterMetaAddBlock,
} from "./Footer.styles";

import logoImage from "assets/images/logo.png";
import LanguageSelector from "components/LanguageSelector/LanguageSelector";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <SFooter>
      <FooterBlock>
        <FooterLanguageBlock>
          <LanguageSelector />
        </FooterLanguageBlock>

        <FooterTopBlock>
          <FooterLogoWrapper>
            <img src={logoImage} alt="logo" />
          </FooterLogoWrapper>
          <div>
            <span>{t("footer_sub_title_1")}</span>
            <span>{t("footer_sub_title_2")}</span>
          </div>
        </FooterTopBlock>

        <FooterMetaBlock>
          <p>{t("footer_business_info")}</p>
          <p>
            {t("footer_address_1")}
            {"\n"}
            <span> | Tel: 070-4800-2616 | admin@eogiya.com</span>
          </p>
          <p>
            {t("footer_address_2")}
            {"\n"}
            <span> | </span>Tel: 070-4800-2616 | admin@eogiya.com
          </p>
          <br />
          <br />
          <p>Copyright ⓒ 2023 Eogiya Factory. All rights reserved.</p>
          <br />
          <br />
          <FooterMetaAddBlock>
            <p>
              {" "}
              본 결과물은 해양수산부와 해양수산과학기술진흥원의 해양수산
              창업기업 사업화 자금 지원 프로그램으로 수행된 결과입니다.
            </p>
          </FooterMetaAddBlock>
        </FooterMetaBlock>
      </FooterBlock>
    </SFooter>
  );
};

export default Footer;
