import { Suspense, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

const AppyLayout = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Header />
      <Suspense fallback={<div />}>
        <Outlet />
      </Suspense>
      <Footer />
    </>
  );
};

export default AppyLayout;
