export const subMenuList = [
  [
    {
      name: 'header_title_1',
      mobileName: 'header_title_1',
      path: '/company',
      state: {
        page: 'introduction',
      },
    },
    {
      name: 'section_sub_title_2',
      mobileName: 'section_sub_title_2',
      path: '/company',
      state: {
        page: 'history',
      },
    },
    {
      name: 'section_title_6',
      mobileName: 'section_title_6',
      path: '/',
      state: {
        page: 'partener',
      },
    },
  ],
  [
    {
      name: 'section_title_1',
      mobileName: 'ACS',
      path: '/business',
      state: {
        page: 'rnd',
      },
    },
    {
      name: 'section_title_2',
      mobileName: 'section_sub_title_4',
      path: '/business',
      state: {
        page: 'ssm',
      },
    },
    {
      name: 'section_title_3',
      mobileName: 'section_title_4',
      path: '/business',
      state: {
        page: 'citycrab',
      },
    },
  ],
  [
    // {
    //   name: '공지사항',
    //   mobileName: '공지사항',
    //   path: '/community',
    //   state: {
    //     page: 'notice',
    //   },
    // },
    // {
    //   name: '보도자료',
    //   mobileName: '보도자료',
    //   path: '/community',
    //   state: {
    //     page: 'press-release',
    //   },
    // },
    {
      name: 'section_title_5',
      mobileName: 'section_title_5',
      path: '/community',
      state: {
        page: 'map',
      },
    },
  ],
] as const;
