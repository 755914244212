import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import transEn from "./File/en.json";
import transKo from "./File/ko.json";

export const languages = [ 'en', 'ko' ] as const;
export type Languages = typeof languages[number]; // 'en' | 'ko'

const resources = {
  en: { translation: transEn },
  ko: { translation: transKo },
};

i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('language') || window.navigator.language || 'ko',
    fallbackLng: 'ko',
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
})

export default i18n;
