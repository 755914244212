import styled from "@emotion/styled";
import { ImageWrapper } from "styles/common.styles";

export const SFooter = styled.footer`
  padding: 52px 49px;
  background: var(--On-Background-1, #333);

  ${({ theme }) => theme.media.lg} {
    padding: 42px 112px 75px;
  }
`;

export const FooterBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${({ theme }) => theme.media.lg} {
    gap: 32px;
  }
`;

export const FooterLanguageBlock = styled.div`
  display: flex;

  select {
    width: 100px;
    height: 30px;
    margin-left: auto;
  }
`;

export const FooterTopBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  div:last-of-type {
    display: flex;
    gap: 24px;

    span {
      color: #fff;
      ${({ theme }) => theme.typography.bodyMo2}
    }
  }

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    justify-content: space-between;

    div:last-of-type {
      gap: 32px;

      span {
        ${({ theme }) => theme.typography.body3}
      }
    }
  }
`;

export const FooterLogoWrapper = styled(ImageWrapper)`
  width: 155px;
  height: 42.288px;
`;

export const FooterMetaBlock = styled.div`
  display: flex;
  flex-direction: column;

  color: rgba(255, 255, 255, 0.6);
  ${({ theme }) => theme.typography.bodyMo1}

  p {
    display: flex;
    white-space: pre-wrap;
    span {
      display: none;
    }
  }

  ${({ theme }) => theme.media.lg} {
    p {
      white-space: initial;
      span {
        display: block;
        white-space: pre-wrap;
      }
    }

    ${({ theme }) => theme.typography.body2}
  }
`;

export const FooterMetaAddBlock = styled.div`
  display: flex;
  flex-direction: column;

  color: #ffffff;
  ${({ theme }) => theme.typography.bodyMo1}

  p {
    display: flex;
    white-space: pre-wrap;
    span {
      display: none;
    }
  }

  ${({ theme }) => theme.media.lg} {
    p {
      white-space: initial;
      span {
        display: block;
        white-space: pre-wrap;
      }
    }

    ${({ theme }) => theme.typography.body2}
  }
`;
