import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import AppyLayout from "../components/AppLayout/AppyLayout";

const Main = lazy(() => import("pages/Main/Main"));
const Company = lazy(() => import("pages/Company/Company"));
const Business = lazy(() => import("pages/Business/Business"));
const Community = lazy(() => import("pages/Community/Community"));

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AppyLayout />}>
        <Route path="/" element={<Main />} />
        {/* Company */}
        <Route path="/company" element={<Company />} />
        {/* Business */}
        <Route path="/business" element={<Business />} />
        {/* Map */}
        <Route path="/community" element={<Community />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
