import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SMobileMenuListItem = styled.li<IsActive>`
  display: flex;
  height: 80px;
  padding: 0px 40px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  cursor: pointer;

  color: var(--On-Background-1, #333);
  ${({ theme }) => theme.typography.body3};

  ${({ isActive }) =>
    isActive &&
    css`
      background: var(--primary, #0f327e);
      color: var(--On-Primary, #fff);
      svg {
        transform: rotate(180deg);
        path {
          fill: var(--On-Primary, #fff);
        }
      }
    `}
`;

export const MobileSubMenuList = styled.ul<IsActive>`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  max-height: 0;
  transition: all 0.5s;

  ${({ isActive }) =>
    isActive &&
    css`
      max-height: 240px;
    `}
`;

export const MobileSubMenuListItem = styled.li`
  display: flex;
  height: 80px;
  padding: 0px 40px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  cursor: pointer;

  color: var(--Gray-1, #666);
  ${({ theme }) => theme.typography.body2};
`;
