import { useCallback, useState } from "react";
import {
  MobileMenuList,
  MobileMenuHeader,
  MobileMenuLogoWrapper,
  SMobileMenuModal,
} from "./MobileMenu.styles";

import { subMenuList } from "../Header.data";
import MobileMenuListItem from "./MobileMenuListItem/MobileMenuListItem";

// import logoImage from 'assets/images/logo_black.png';
import logoImage from "assets/images/logo.png";
import { ReactComponent as CloseIcon } from "assets/images/header/close.svg";

import { useTranslation } from "react-i18next";

type TSeletedMenu = "회사소개" | "사업소개" | "커뮤니티";
interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const MobileMenu = ({ isOpen, onClose }: MobileMenuProps) => {
  const [seletedMenu, setSeletedMenu] = useState<TSeletedMenu[]>([]);
  const { t } = useTranslation();

  const onClickMenu = useCallback(
    (menu: TSeletedMenu) => () => {
      setSeletedMenu((prev) => {
        if (prev.includes(menu)) {
          return prev.filter((item) => item !== menu);
        }

        return [...prev, menu];
      });
    },
    []
  );

  return (
    <SMobileMenuModal isOpen={isOpen}>
      <MobileMenuHeader>
        <MobileMenuLogoWrapper>
          <img src={logoImage} alt="logo" />
        </MobileMenuLogoWrapper>
        <CloseIcon onClick={onClose} />
      </MobileMenuHeader>

      <MobileMenuList>
        <MobileMenuListItem
          menuItem={t("header_title_1")}
          onClick={onClickMenu(t("header_title_1"))}
          isActive={seletedMenu.includes(t("header_title_1"))}
          subMenu={subMenuList[0]}
          onClose={onClose}
        />

        <MobileMenuListItem
          menuItem={t("header_title_2")}
          onClick={onClickMenu(t("header_title_2"))}
          isActive={seletedMenu.includes(t("header_title_2"))}
          subMenu={subMenuList[1]}
          onClose={onClose}
        />

        <MobileMenuListItem
          menuItem={t("header_title_3")}
          onClick={onClickMenu(t("header_title_3"))}
          isActive={seletedMenu.includes(t("header_title_3"))}
          subMenu={subMenuList[2]}
          onClose={onClose}
        />
      </MobileMenuList>
    </SMobileMenuModal>
  );
};

export default MobileMenu;
