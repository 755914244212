import { useEffect, useMemo, useState } from 'react';
import { throttle } from 'lodash';

const useScroll = () => {
  const [isScrollDown, setIsScrollDown] = useState(false);

  const scrollHandler = useMemo(
    () =>
      throttle(() => {
        setIsScrollDown(window.scrollY !== 0);
      }, 300),
    []
  );

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollHandler]);

  return { isScrollDown };
};

export default useScroll;
