import styled from '@emotion/styled';
import Modal from 'react-modal';
import { ImageWrapper } from 'styles/common.styles';

export const SMobileMenuModal = styled(Modal)`
  position: fixed;
  inset: 0;
  background: #fff;

  ${({ theme }) => theme.media.lg} {
    display: none;
  }
`;

export const MobileMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  height: 50px;
`;

export const MobileMenuLogoWrapper = styled(ImageWrapper)`
  width: 120px;
  height: 22.63px;
  flex-shrink: 0;
`;

export const MobileMenuList = styled.ul`
  padding-top: 150px;
  height: calc(100vh - 50px);
  overflow: auto;
`;
