const customMediaQuery = (minWidth: number) =>
  `@media (min-width: ${minWidth}px)`;

const media = {
  custom: customMediaQuery,
  md: customMediaQuery(768),
  lg: customMediaQuery(1220),
  xl: customMediaQuery(1700),
};

export default media;
