import {
  HeaderLogo,
  MenuIconWrapper,
  NavigationList,
  SHeader,
  SubMenuBlock,
  SubMenuContainer,
} from "./Header.styles";

import useScroll from "hooks/useScroll";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { subMenuList } from "./Header.data";
import MobileMenu from "./MobileMenu/MobileMenu";

import { ReactComponent as MenuIcon } from "assets/images/header/menu.svg";

import { useTranslation } from "react-i18next";

const Header = () => {
  const [visibleSubMenu, setVisibleSubMenu] = useState(false);
  const [visibleMobileMenu, setVisibleMobileMenu] = useState(false);
  const { isScrollDown } = useScroll();
  const { t } = useTranslation();

  const isActive = useMemo(
    () => visibleSubMenu || isScrollDown,
    [isScrollDown, visibleSubMenu]
  );

  const onOpenSubMenu = useCallback(() => setVisibleSubMenu(true), []);
  const onCloseSubMenu = useCallback(() => setVisibleSubMenu(false), []);

  const onClickMobileMenu = useCallback(() => setVisibleMobileMenu(true), []);
  const onCloseMobileMenu = useCallback(() => setVisibleMobileMenu(false), []);

  return (
    <SHeader isActive={isActive} onMouseLeave={onCloseSubMenu}>
      <Link to="/">
        <HeaderLogo isActive={isActive} />
      </Link>

      {/* PC */}
      <nav>
        <NavigationList isActive={isActive}>
          <Link
            to={"/company"}
            // key={subMenuItem.name}
            onClick={onCloseSubMenu}
            state={{ page: "introduction" }}
          >
            <li onMouseEnter={onOpenSubMenu}>{t("header_title_1")}</li>
          </Link>
          <Link
            to={"/business"}
            // key={subMenuItem.name}
            onClick={onCloseSubMenu}
            state={{ page: "rnd" }}
          >
            {/* {" "} */}
            <li onMouseEnter={onOpenSubMenu}>{t("header_title_2")}</li>
          </Link>
          <Link
            to={"/community"}
            // key={subMenuItem.name}
            onClick={onCloseSubMenu}
            state={{ page: "map" }}
          >
            <li onMouseEnter={onOpenSubMenu}>{t("header_title_3")}</li>
          </Link>
        </NavigationList>
      </nav>
      <SubMenuContainer isVisible={visibleSubMenu}>
        <SubMenuBlock>
          {subMenuList.map((subMenu, i) => (
            <ul key={i}>
              {subMenu.map((subMenuItem) => (
                <Link
                  to={subMenuItem.path}
                  key={t(subMenuItem.name)}
                  onClick={onCloseSubMenu}
                  state={subMenuItem.state}
                >
                  <li>{t(subMenuItem.name)}</li>
                </Link>
              ))}
            </ul>
          ))}
        </SubMenuBlock>
      </SubMenuContainer>

      {/* Mobile */}
      <MenuIconWrapper isActive={isActive} onClick={onClickMobileMenu}>
        <MenuIcon />
      </MenuIconWrapper>
      <MobileMenu isOpen={visibleMobileMenu} onClose={onCloseMobileMenu} />
    </SHeader>
  );
};

export default Header;
